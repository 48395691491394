.wizardbuttonContainer {
    background-color: #fff;
    width: 100%;
    max-width: 920px;
    margin-top: 20px;
}

.wizardbutton {
    border-radius: 25px;
    color: white;
    background-color: black;
    border: none;
    padding: 5px 20px;
    margin: 0 auto;
    width: 280px;
    transition: 0.5s;
    font-size: 18px;
    cursor: pointer;
    font-family: "Poppins Regular";
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    &:hover {
        transform: translateX(-50%) scale(1.1);
    }
}

.wizardBackButton {
    position: absolute;
    top: 20px;
    left: 50px;
    width: 50px;
    height: 50px;
    background: transparent;
    border: none;
    transform: rotate(180deg);
    cursor: pointer;
    z-index: 25;
    transition: 0.5s;
    &:hover {
        transform: scale(1.1) rotate(180deg);
    }
}

.wizardBlackBackButton {
    position: absolute;
    top: 20px;
    left: 50px;
    width: 50px;
    height: 50px;
    background: #000;
    border-radius: 50%;
    transform: rotate(180deg);
    cursor: pointer;
    z-index: 25;
    transition: 0.5s;
    &:hover {
        transform: scale(1.1) rotate(180deg);
    }
}

.refreshSceneButton {
    position: absolute;
    top: 20px;
    left: 120px;
    width: 50px;
    height: 50px;
    background: transparent;
    border: none;
    transform: rotate(180deg);
    cursor: pointer;
    z-index: 2;
    transition: 0.5s;
    &:hover {
        transform: scale(1.1);
    }
}

// .closebutton {
//     background-color: transparent;
//     border: none;
//     font-size: 24px;
//     font-weight: bold;
//     cursor: pointer;
// }

.closebutton {
    background-color: transparent;
    border: none;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    width: 30px;
    height: 30px;
    transform: rotate(180deg);
}

.addbutton {
    color: black;
    background-color: var(--light-grey);
    font-size: 30px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
}

.sidebarSelection {
    display: block;
    border-radius: 25px;
    color: black;
    background-color: #F2F2F2;
    padding: 5px 20px;
    margin: 5px 7px 5px 0;
    white-space: nowrap;
    border: 2px solid #F2F2F2;
    width: fit-content;
    transition: 0.5s;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    font-family: "Poppins Regular";
    &:hover {
        transform: scale(1.05);
    }
}

.activeSidebarSelection {
    display: block;
    border-radius: 25px;
    color: white;
    background-color: black;
    padding: 5px 20px;
    margin: 5px 7px 5px 0;
    white-space: nowrap;
    border: 2px solid black;
    width: fit-content;
    transition: 0.5s;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    font-family: "Poppins Regular";
    &:hover {
        transform: scale(1.05);
    }
}

.addElement {
    border-radius: 25px;
    color: white;
    background-color: var(--secondary);
    font-size: 18px;
    border: none;
    padding: 15px 30px;
    margin: 25px 0 25px 0;
}

.closeEditMode {
    border-radius: 25px;
    color: black;
    background-color: var(--light-grey);
    font-size: 18px;
    border: none;
    padding: 15px 30px;
    margin: 25px 0 25px 0;
}

.whiteButtonOptions {
    background-color: #fff;
    font-size: 18px;
    width: 160px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    .imageOptionsContainer {
        border: 2px solid #000;
        width: 96px;
        height: 96px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    & img {
        width: 50px; 
        height: 50px;
    }
    & p {
        font-size: 16px;
        margin: 30px 0 0 !important;
        color: black;
        white-space: nowrap;
    }
}

.blackButtonOptions {
    border-radius: 25px;
    background-color: var(--secondary);
    font-size: 18px;
    border: none;
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    width: 250px;
    height: 50px;
    & img {
        width: 25px;
        height: 25px;
    }
    & p {
        font-size: 16px;
        margin: 0 !important;
        color: white !important;
    }
}

.allMaterialsButton {
    color: black;
    background-color: var(--light-grey);
    font-size: 18px;
    border: none;
    padding: 15px 30px;
    margin: 25px 0;
}


.frontenButton {
    background-color: #fff;
    font-size: 18px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 5;
    border-radius: 50%;
    bottom: 100px !important;
    left: 110px;
    width: 50px;
    height: 50px;
    & img {
        width: 40px; 
        height: 40px;
    }
}


.buyButton {
    border-radius: 25px;
    color: white;
    font-size: 21px;
    background-color: black;
    border: none;
    padding: 10px 25px;
    margin: 25px;
    transition: 0.5s;
    font-family: "Poppins Regular";
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }
}


.buyButtonTransparent {
    border-radius: 25px;
    color: white;
    font-size: 21px;
    background-color: rgba(0,0,0,0.3);
    border: none;
    padding: 10px 25px;
    margin: 25px;
    transition: 0.5s;
    font-family: "Poppins Regular";
}


.sidebarButton {
    width: auto;
    position: absolute;
    bottom: 75px;
    right: 20px;
    z-index: 4;
    padding: 5px 15px 5px 20px;
    border-radius: 25px;
    display: none;
    background-color: #ffffff;
    color: black;
    & p {
        white-space: nowrap;
        display: inline; 
        margin: 0;
    }
    & img {
        display: inline;
        transform: rotate(90deg);
    }
}

.whiteModalButton {
    display: block;
    border-radius: 25px;
    color: black;
    background-color: white;
    padding: 5px 20px;
    margin: 25px auto;
    white-space: nowrap;
    border: 2px solid black;
    width: 295px;
    transition: 0.5s;
    font-size: 15px;
    cursor: pointer;
    font-family: "Poppins Regular";
    &:hover {
        transform: scale(1.1);
    }
}

.blackModalButton {
    display: block;
    border-radius: 25px;
    color: white;
    background-color: black;
    padding: 5px 20px;
    margin: 25px auto;
    white-space: nowrap;
    border: 2px solid black;
    width: 295px;
    transition: 0.5s;
    font-size: 15px;
    cursor: pointer;
    font-family: "Poppins Regular";
    &:hover {
        transform: scale(1.1);
    }
}

// Alerts

.alertButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & img {
        width: 20px;
        height: 20px;
    }
    & p {
        color: red !important;
    }
}

.alertParagraph{
    margin-top: 0 !important;
}

// Queries

@media (max-width: 1400px){
    .sidebarButton{
        display: flex;
        align-items: center;
    }
    .sidebarButton p {
        font-size: 13px;
    }
    .wizardBackButton {
        background: #000;
        z-index: 5;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        left: 20px;
    }
    .wizardBlackBackButton {
        width: 40px;
        height: 40px;
        left: 20px;
    }

    .refreshSceneButton {
        z-index: 5;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        left: 70px;
    }
    .frontenButton {
        bottom: 75px !important;
        width: 40px;
        height: 40px;
        left: 70px;
        & img {
            width: 30px;
            height: 30px;
        }
    }
}

@media (max-width:992px){
    .wizardbuttonContainer {
        width: 100vw;
        position: fixed;
        bottom: 0;
        padding-right: 40px;
    }
}

@media (max-width: 576px) {
    .wizardbutton {
        margin: 25px auto;
    }
}

@media (max-width: 450px){
    .buyButton {
        font-size: 16px;
    }
    .sidebarButton p {
        font-size: 11px;
    }
}

