.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: scroll;
    & .wizardimg {
        width: 40%;
        height: 100%;
        background-image: url('../../public/images/wizardimg.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    & .wizardwrapper {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 100px;
      justify-content: center;
      & .wizardwrapper_innercontainer {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        padding: 25px 0;
      }
      & .wizardcontainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0 auto 30px 60px;
        position: relative;
        & .headline {
            font-family: "Poppins Regular";
            font-size: 40px;
        }
        & .desaroHeadline {
            position: relative;
            bottom: 20px;
        }
        & .introQuestion {
            margin: 30px 0;
        }
        & .variationsContainer {
            display: flex;
            gap: 20px;
        }
        & .variationsWrapper { 
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 10px 0;
            border: 2px solid var(--dark-brown);
            height: 230px;
            width: 230px;
            position: relative;
            & img {
                width: 180px;
            }
            .addSymbol {
                background-color: rgba(110,89,67, 0.3);
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 50;
                & img {
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    bottom: 20px;
                    left: 20px;
                }
            }
        }
        & .elementBar {
            display: flex;
            gap: 25px;
            margin-bottom: 25px;
            align-items: flex-end;
            flex-wrap: wrap;
            & p:hover {
                cursor: pointer;
            }
            & .boldElement {
                font-weight: bold;
            }
        }
        & .materialBar {
            display: flex;
            gap: 25px;
            font-size: 14px;
            margin-bottom: 30px;
            flex-wrap: wrap;
            & span {
                font-size: 16px;
            }
        }
        & .materialoptions {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin: 0 60px 0 0;
            max-width: 920px;
            & > div {
                width: 145px;
                & img {
                    width: 100%;
                    display: block;
                    max-width: 100px;
                    margin: 0 auto 10px;
                }
                p {
                    text-align: center;
                    white-space: nowrap;
                    font-size: 14px;
                }
            }
        }
      }
    }
    & .optionswrapper {
        display: flex;
        gap: 25px;
        height: 500px;
        width: 100%;
        & .singleOption {
            width: 28%;
            transition: transform 0.5s;
            &:hover {
                transform: scale(1.02);
            }
        }
        & .widerSingleOption {
            width: 33%;
        }
        & .startNewWrapper {
            width: 100%;
            height: 100%;
            background-image: url('../../public/images/startNewNoBands.jpg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin-bottom: 30px;
        }
        & .variations  {
            width: 100%;
            height: 100%;
            background-image: url('../../public/images/variations.jpg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin-bottom: 30px;
        }
        & .inspirations  {
            width: 100%;
            height: 100%;
            background-image: url('../../public/images/inspirations.jpg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin-bottom: 30px;
        }
        & .presetwrapper {
            padding: 25px;
            height: 240px;
            overflow-y: scroll;
            overflow-x: hidden;
            & .presetoptions {
                margin-top: 25px;
                display: flex;
                gap: 25px;
                flex-wrap: wrap;
                justify-content: center;
            }
        }
        & .emptywrapper {
            background-color: #00000014;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 1 33%;
            font-weight: bold;
            padding: 25px;
        }
    }
    & .wizardbuttonrow {
        width: 90%;
        display: flex;
        justify-content: flex-end;
        gap: 50px;
    }
    & .wizardbuttonrowsingle {
        display: flex;
        flex-direction: row;
        justify-content: right;
        width: 100%;
        flex-wrap: wrap;
    }
    & .bulletpoints {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex: 0 0 100%;
    }
}

.optionsCaption {
    font-family: "Poppins SemiBold";
    white-space: nowrap;
}
.optionIcon {
    width: 20px;
    height: 20px;
}

.variationsText {
    max-width: 420px;
    position: absolute;
    bottom: -180px;
    right: 130px;
    & p {
        margin-bottom: 20px;
    }
}

.inspirationsSlide {
    position: absolute;
    z-index: 20;
    width: 100vw;
    height: 100vh;
    background: #fff;
    display: flex;
    align-items: center;
    & img {
        width: 50%;
        margin: 0 auto;
    }
}

.wizardElementToAdd {
    box-shadow: 0 0 0 3px var(--primary);
}


.wizardMaterialToAdd {
    box-shadow: 0 0 0 3px var(--primary);
    border-radius: 50%;
}

// Queries 

@media (max-width: 1400px) {
    .wizardimg {
        display: none;
    }
    .wrapper .wizardwrapper {
        width: 100%;
        padding: 0 20px !important;
        justify-content: flex-start !important;
        margin-top: 150px;
    }

    .wizardwrapper_innercontainer {
        padding: 0 !important;
    }

    .wrapper .wizardwrapper .wizardcontainer {
        margin: 0;
    }

    .singleOption {
        width: 30% !important;
    }

    .widerSingleOption {
        width: 40% !important;
    }
}

@media (max-width: 992px){
    // .wrapper {
    //     overflow: scroll !important;
    //     height: auto !important;
    // }

    .optionswrapper {
        flex-direction: column !important;
        height: auto !important;
    }

    .singleOption {
        width: 60% !important;
        margin: 25px auto;
        text-align: center;
    }

    .startNewWrapper, .variations, .inspirations {
        background-size: contain;
        height: 500px !important;
    }
    .variationsContainer{
        flex-wrap: wrap !important;
    }
    .variationsText {
        position: static !important;
        margin: 50px 0;
    }
    .carousel-indicators {
        bottom: -50px;
    }
    .materialoptions {
        margin-bottom: 120px !important;
    }
}

@media (max-width: 576px) {
    .startNewWrapper, .variations, .inspirations {
        background-size: contain;
        height: 330px !important;
    }
    .materialBar span {
        flex: 1 0 100%;
    }
    .materialoptions {
        margin-right: 0 !important;
    }
    .singleOption {
        width: 100% !important;
    }
    .variationsContainer{
        justify-content: center !important;
    }
}

@media (max-width: 480px) {
    .materialoptions div {
        width: auto;
        flex: 0 0 45%;
    }
    .materialoptions p {
        font-size: 12px;
    }
}


