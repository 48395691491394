// Fonts 

.semiBold {
    font-family: "Poppins Semibold";
  }

.sidebarArrow {
    width: 10%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    max-width: 50px;
    cursor: pointer;
}

.sidebarwrapper {
    z-index: 5;
    width: 34%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    right: 0;
    transition: 0.5s;
    & .sidebarwrapper_innercontainer {
        padding: 25px 50px;
        margin-bottom: 150px !important;
        & h2 {
            margin-bottom: 25px;
        }
        & .sidebarOptions {
            display: flex;
            flex-wrap: wrap;
            column-gap: 25px;
            row-gap: 20px;
        }
        & .buttonsTopRow {
            display: flex;
            flex-wrap: wrap;
        }
        & .sidebarOption {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 160px;
            & img {
                width: 100px;
                height: 100px;
            }
            & p {
                font-size: 16px;
                margin: 30px 0 0;
                white-space: nowrap;
            }
        }
        & .introduction {
            max-width: 350px;
            margin-bottom: 50px;
        }
        & .introQuestion {
            margin-bottom: 50px;
        }
    }
    & h1, h4, p {
        color: black;
        margin: 30px 0;
    }
    & .elementTypeSelection {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        flex-direction: column;
        margin-top: 50px;
        & p {
            margin: 0;
        }
        & .singleTypeSelection {
            position: relative;
            & p::after {
                content: "";
                display: block;
                width: 20px;
                height: 20px;
                background: url(../../public/images/icons/chevron-right-regular.svg);
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                right: 25px;
                bottom: 0;
            }
        } 
        & .typeGoBack{
            display: flex;
            justify-content: flex-end;
            position: relative;
            & p::before {
                content: "";
                display: block;
                width: 20px;
                height: 20px;
                background: url(../../public/images/icons/chevron-right-regular.svg);
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                left: 0;
                bottom: 0;
                transform: rotate(180deg);
            }
        }
    }
    & .buttonwrapper{
        display: flex;
        gap: 25px;
    }
    & .materialswrapper {
        display: flex;
        gap: 50px;
        margin: 50px 0 100px 0;
        & p {
            text-align: center;
        }
        & img {
            width: 100%;
            height: auto;
        }
    }
    & .materialwrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        & p {
            white-space: nowrap;
        }
        & img {
            width: 100px;
        }
    }
}

.greyscale {
    filter: grayscale(100%);
}

.colored {
    filter: grayscale(0);
}

.elementsContainer {
    display: flex;
    flex-wrap: wrap;
    min-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    column-gap: 0;
    row-gap: 20px;
    margin: 0 auto 0 0;
    scrollbar-width: thin !important;
    scrollbar-color: #ffffff var(--dark-brown) !important; 
    max-width: 600px;
    min-width: 300px;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background-color: #ffffff; /* Color of the scroll track for WebKit */
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--dark-brown); /* Color of the thumb for WebKit */
        border-radius: 5px;
    }
    & .elementsWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 10px;
        border: 2px solid var(--dark-brown);
        height: 150px;
        width: 150px;
        position: relative;
        & p {
            font-size: 11px;
            white-space: nowrap;
            margin: 5px 0 0 0;
        }
        & .addSymbol {
            background-color: rgba(110,89,67, 0.3);
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 50;
            & img {
                width: 30%;
                height: 30%;
            }
        }
    }
    & img {
        width: 100px;
        height: 100px;
    }
    .elementToAdd {
        box-shadow: 0 0 0 3px var(--primary);
    }
}

.elementsContainerClosed {
    height: 0px;
    min-height: 0px;
}

.currentElementContainer {
    display: flex;
    margin-top: 15px;
    & p {
        margin: 0 0 10px;
    }
    & .currentElementImageContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 10px;
        border: 2px solid var(--dark-brown);
        height: 150px;
        width: 150px;
        position: relative;
        & img {
            width: 100px;
            height: 100px;
        }
    }
}

.currentSizesCaptionFont {
    font-size: 18px;
}

.currentSizesFont {
    font-size: 13px;
}

.transparentModel {
    opacity: 0.5;
    cursor: not-allowed !important;
    &:hover {
        transform: none;
    }
}

.configurationwrapper {
    bottom: 0;
    right: 0;
    height: 80px;
    width: 34%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow-y: hidden;
    background: #ffffff;
    z-index: 10;
    & .configurationwrapper_innercontainer {
        padding: 0 25px 10px;
        display: flex;
    gap: 50px;
    flex-wrap: nowrap;
    align-items: center;
    }
    & h5, span {
        color: black;
    }
    & .price {
        display: flex;
        flex-direction: column;
        margin: 0 30px;
        & .sum {
            font-weight: bold;
            font-size: 30px;
            white-space: nowrap;
        }
        & .tax {
            font-size: 12px;
        }
    }
}

.warning {
    color: red !important;
    font-weight: bold;
}

div [role="alert"]{
    position: fixed;
    top: 40vh;
    z-index: 99;
}

// dynamic sidebar classes 

.visibleSidebar {
    opacity: 1;
    pointer-events: all;
    height: 60%;
}

.hiddenSidebar {
    opacity: 0;
    pointer-events: none;
    height: 0;
}

.smooth {
    transition: 0.5s;
}

// Queries 

@media (min-width: 1400px) {
    .visibleSidebar {
        height: 100%;
    }
}

@media (max-width: 1400px){
    .sidebarwrapper {
        background: #ffffff;
        width: 100%;
    }
    .configurationwrapper {
        width: 100%;
    }
    .elementsContainerDrawers {
        justify-content: center;
    }
    .sidebarwrapper{
        width: 100%;
        top: 40%;
        height: 60%;
    }
}

@media (max-width: 450px){
    .elementsWrapper {
        width: 130px;
    }
    .sidebarwrapper .sidebarwrapper_innercontainer {
        padding: 25px 10px;
    }
    .configurationwrapper .price .sum {
        font-size: 18px;
    }
    .configurationwrapper .configurationwrapper_innercontainer {
        padding: 10px;
        gap: 10px;
        & .price .tax {
            font-size: 10px;
            white-space: nowrap;
        }
    }
}