/* Root Declarations */

/* Colors */

:root {
  --primary: #D3AF8C;
  --secondary: #000;
  --light-grey: #00000014;
  --selected-grey: #ece9e7;
  --dark-brown: #6e5943;
}

.App {
  width: 100vw;
  height: 100%;
}


/* Root Declarations END */

body {
margin: 0;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@font-face {
  font-family: 'Poppins Bold';
  src: url('../public/fonts/Poppins-Bold.woff') format('woff'),
       url('../public/fonts/Poppins-Bold.woff2') format('woff2'),
       url('../public/fonts/Poppins-Bold.eot') format('eot');
}

@font-face {
  font-family: 'Poppins Regular';
  src: url('../public/fonts/Poppins-Regular.woff') format('woff'),
       url('../public/fonts/Poppins-Regular.woff2') format('woff2'),
       url('../public/fonts/Poppins-Regular.eot') format('eot');
}

@font-face {
  font-family: 'Poppins SemiBold';
  src: url('../public/fonts/Poppins-SemiBold.woff') format('woff'),
       url('../public/fonts/Poppins-SemiBold.woff2') format('woff2'),
       url('../public/fonts/Poppins-SemiBold.eot') format('eot');
}

h2 {
  font-family: "Poppins Bold";
  font-size: 40px;
}

h3 {
  font-family: "Poppins Bold";
  font-size: 30px;
}

p, span, li {
  font-family: "Poppins Regular";
  font-size: 20px;
  color: #000;
  // margin-bottom: 0 !important;
}

hr {
  margin: 0 !important;
}

.semiBold {
  font-family: "Poppins Semibold";
}

.logo {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 50px;
  top: 20px;
  z-index: 50;
}

@media (max-width: 992px){
  .App {
    height: auto !important;
  }
  .carousel-indicators {
    bottom: -50px !important;
  }
}

@media (max-width: 450px){
  .logo {
    right: 10px;
    width: 40px;
    height: 40px;
  }
}

